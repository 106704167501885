import { useEffect } from 'react';
import Helmet from "react-helmet"
import { getCurrentLocation } from "../../tools"
import siteData from "../../data";
import Showcase from '../../components/ui/Showcase';
import tanklessWaterHeaters from "../../assets/images/FH11DJA_WATHEA_05-1173x660.webp"

const TanklessWaterHeaters = () => {
    const currentLocation = getCurrentLocation()
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <main className="mt-[100px] pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tankless Water Heaters - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Upgrade to a tankless water heater for endless hot water, energy savings, and space efficiency. Enjoy comfort without compromise!" />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}tankless-water-heaters`} />
            </Helmet>
            <Showcase info={{
                title: "Upgrade To A Tankless Water Heater",
                subTitle: "A.A. Richards Heating, Cooling, & Plumbing",
                description: "Upgrade to a tankless water heater for endless hot water, energy savings, and space efficiency. Enjoy comfort without compromise!",
                image: {
                    src: tanklessWaterHeaters,
                    alt: "",
                    width: 1173,
                    height: 660
                }
            }} />
            
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Call our {currentLocation.name} office at <a href={"tel:" + currentLocation.salesPhone.value} className='py-3 text-lg text-blue-500 font-bold sm:w-[60%] my-4'>{currentLocation.salesPhone.display}</a> to get started.</p>
            <h2 className='py-3 text-2xl text-center md:text-3xl font-bold'>Why Choose A.A. Richards Heating, Cooling, & Plumbing?</h2>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Unlike traditional tank-type water heaters, tankless water heaters don’t constantly heat and hold hot water in a large tank. Instead, they heat the water right when you need it, then stop heating it when you turn the hot water off. For this reason, they are also called on-demand water heaters. This saves energy by not continuously heating water. It also ensures that you’ll never have to take another cold shower again! And without a tank, these water heaters are more compact and can be installed in smaller spaces.</p>
        </main>
    )
}

export default TanklessWaterHeaters