import { useEffect } from 'react';
import Helmet from "react-helmet"
import { getCurrentLocation } from "../../tools"
import Showcase from '../../components/ui/Showcase';
import tankles from "../../assets/images/tankless-boiler-installed.webp"

const Plumbing = () => {
    const currentLocation = getCurrentLocation()
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <main className="mt-[100px] pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Plumbing - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Our team of experienced plumbing technicians is ready to handle any plumbing issue you may be experiencing from diagnosis to repair." />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}plumbing`} />
            </Helmet>
            <Showcase info={{
                title: "We Specialize in Plumbing Solutions",
                subTitle: "A.A. Richards Heating, Cooling, & Plumbing",
                description: "Our team of experienced plumbing technicians is ready to handle any plumbing issue you may be experiencing from diagnosis to repair.",
                image: {
                    src: tankles,
                    alt: "",
                    width: 1885,
                    height: 1414
                }
            }} />
            
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Call our {currentLocation.name} office at <a href={"tel:" + currentLocation.salesPhone.value} className='py-3 text-lg text-blue-500 font-bold sm:w-[60%] my-4'>{currentLocation.salesPhone.display}</a> to get started.</p>
            <h2 className='py-3 text-2xl text-center md:text-3xl font-bold'>Why Choose A.A. Richards Heating, Cooling, & Plumbing?</h2>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>When it comes to your home's plumbing system, installation and replacement are two of the most critical services. Proper installation ensures a solid foundation for your plumbing's performance, while timely replacement keeps your system running smoothly and efficiently.</p>
        </main>
    )
}

export default Plumbing;