import { useEffect } from 'react';
import Helmet from "react-helmet"
import { getCurrentLocation } from "../../tools"
import Showcase from '../../components/ui/Showcase';
import airDuctCleaning from "../../assets/images/20240212_135009-1-scaled-700x600.webp"

const AirDuctCleaning = () => {
    const currentLocation = getCurrentLocation()
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <main className="mt-[100px] pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Air Duct Cleaning - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Air duct cleaning removes dust, allergens, and debris, improving indoor air quality, energy efficiency, and overall home health." />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}air-duct-cleaning`} />
            </Helmet>
            <Showcase info={{
                title: "We Specialize in Air Duct Cleaning",
                subTitle: "A.A. Richards Heating, Cooling, & Plumbing",
                description: "Air duct cleaning removes dust, allergens, and debris, improving indoor air quality, energy efficiency, and overall home health.",
                image: {
                    src: airDuctCleaning,
                    alt: "",
                    width: 700,
                    height: 600
                }
            }} />
            
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Call our {currentLocation.name} office at <a href={"tel:" + currentLocation.salesPhone.value} className='py-3 text-lg text-blue-500 font-bold sm:w-[60%] my-4'>{currentLocation.salesPhone.display}</a> to get started.</p>
            <h2 className='py-3 text-2xl text-center md:text-3xl font-bold'>Why Choose A.A. Richards Heating, Cooling, & Plumbing?</h2>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Regular duct cleanings are one of the best things you can do for your home’s air quality and HVAC systems. Our duct cleanings are great because they are performed by trained technicians. We also always arrive prepared, in fully stocked and high-powered trucks.</p>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Duct cleanings help your home because they get rid of odors, dust, and allergens hiding in your ductwork. They also improve your indoor air quality and your home’s overall efficiency. If you’re ready to invest in duct cleaning services, we’re ready to help you with this process.</p>
        </main>
    )
}

export default AirDuctCleaning