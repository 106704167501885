import { useEffect } from 'react';
import Helmet from "react-helmet"
import { getCurrentLocation } from "../../tools"
import Showcase from '../../components/ui/Showcase';
import heatPump from "../../assets/images/heat_pump_condenser.webp"

const Cooling = () => {
    const currentLocation = getCurrentLocation()
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <main className="mt-[100px] pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Cooling - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="A new AC unit can provide more efficient cooling, reduce energy bills, increase home value, and prevent costly repairs and peace of mind." />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}cooling`} />
            </Helmet>
            <Showcase info={{
                title: "We Specialize in Cooling Systems",
                subTitle: "A.A. Richards Heating, Cooling, & Plumbing",
                description: "A new AC unit can provide more efficient cooling, reduce energy bills, increase home value, and prevent costly repairs and peace of mind.",
                image: {
                    src: heatPump,
                    alt: "",
                    width: 862,
                    height: 539
                }
            }} />
            
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Call our {currentLocation.name} office at <a href={"tel:" + currentLocation.salesPhone.value} className='py-3 text-lg text-blue-500 font-bold sm:w-[60%] my-4'>{currentLocation.salesPhone.display}</a> to get started.</p>
            <h2 className='py-3 text-2xl text-center md:text-3xl font-bold'>Why Choose A.A. Richards Heating, Cooling, & Plumbing?</h2>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>When you consider your home’s air conditioning, the installation and the replacement are two of the most important services you can have performed. This is because installation and replacement set the foundation for your air conditioning quality.</p>
        </main>
    )
}

export default Cooling;