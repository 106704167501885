import { useEffect } from 'react';
import Helmet from "react-helmet"
import { getCurrentLocation } from "../../tools"
import Showcase from '../../components/ui/Showcase';
import dryerVentCleaning from "../../assets/images/dryer-vent-cleaning-before-after-1200x800.webp"

const DryerVentCleaning = () => {
    const currentLocation = getCurrentLocation()
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <main className="mt-[100px] pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dryer Vent Cleaning - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Boost dryer efficiency and prevent fire hazards with professional vent cleaning. Keep your home safe and your dryer running smoothly!" />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}dryer-vent-cleaning`} />
            </Helmet>
            <Showcase info={{
                title: "Need A Dryer Vent Cleaning?",
                subTitle: "A.A. Richards Heating, Cooling, & Plumbing",
                description: "Boost dryer efficiency and prevent fire hazards with professional vent cleaning. Keep your home safe and your dryer running smoothly!",
                image: {
                    src: dryerVentCleaning,
                    alt: "",
                    width: 1200,
                    height: 800
                }
            }} />
            
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Call our {currentLocation.name} office at <a href={"tel:" + currentLocation.salesPhone.value} className='py-3 text-lg text-blue-500 font-bold sm:w-[60%] my-4'>{currentLocation.salesPhone.display}</a> to get started.</p>
            <h2 className='py-3 text-2xl text-center md:text-3xl font-bold'>Why Choose A.A. Richards Heating, Cooling, & Plumbing?</h2>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Dryer vents should be routinely maintained every year to prevent dryer fires. We don't make a mess in your house because while our vacuum is connected to the dryer vent it's under negative pressure ensuring no dust will be blown into the house.</p>
        </main>
    )
}

export default DryerVentCleaning;