import { useEffect } from 'react';
import Helmet from "react-helmet"
import { getCurrentLocation } from "../../tools"
import Showcase from '../../components/ui/Showcase';
import boilers from "../../assets/images/tankless-boiler-installed.webp"

const Boilers = () => {
    const currentLocation = getCurrentLocation()
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <main className="mt-[100px] pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Boilers - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Upgrade to a high-efficiency boiler for consistent heating, lower energy bills, and long-lasting comfort. Reliable warmth, year-round!" />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}boilers`} />
            </Helmet>
            <Showcase info={{
                title: "Upgrade to Efficient Boiler Heating Today!",
                subTitle: "A.A. Richards Heating, Cooling, & Plumbing",
                description: "Upgrade to a high-efficiency boiler for consistent heating, lower energy bills, and long-lasting comfort. Reliable warmth, year-round!",
                image: {
                    src: boilers,
                    alt: "",
                    width: 1885,
                    height: 1414
                }
            }} />
            
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Call our {currentLocation.name} office at <a href={"tel:" + currentLocation.salesPhone.value} className='py-3 text-lg text-blue-500 font-bold sm:w-[60%] my-4'>{currentLocation.salesPhone.display}</a> to get started.</p>
            <h2 className='py-3 text-2xl text-center md:text-3xl font-bold'>Why Choose A.A. Richards Heating, Cooling, & Plumbing?</h2>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>We understand that radiant heating is common in New Jersey, so we provide boiler services that are well within our area of expertise. You can rest assured that our professionals can handle what you need.</p>
        </main>
    )
}

export default Boilers;