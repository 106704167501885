import { useEffect } from 'react';
import Helmet from "react-helmet"
import { getCurrentLocation } from "../../tools"
import Showcase from '../../components/ui/Showcase';
import drainClearing from "../../assets/images/drain-cleaning-1008x756.webp"

const DrainClearing = () => {
    const currentLocation = getCurrentLocation()
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <main className="mt-[100px] pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Drain Clearing - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Clear your drains for smooth, hassle-free flow! Prevent clogs, avoid damage, and keep your plumbing in top shape with our expert drain clearing services." />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}drain-clearing`} />
            </Helmet>
            <Showcase info={{
                title: "Don’t Let A Clogged Drain Plug Up your Day!",
                subTitle: "A.A. Richards Heating, Cooling, & Plumbing",
                description: "Clear your drains for smooth, hassle-free flow! Prevent clogs, avoid damage, and keep your plumbing in top shape with our expert drain clearing services.",
                image: {
                    src: drainClearing,
                    alt: "",
                    width: 1008,
                    height: 756
                }
            }} />
            
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Call our {currentLocation.name} office at <a href={"tel:" + currentLocation.salesPhone.value} className='py-3 text-lg text-blue-500 font-bold sm:w-[60%] my-4'>{currentLocation.salesPhone.display}</a> to get started.</p>
            <h2 className='py-3 text-2xl text-center md:text-3xl font-bold'>Why Choose A.A. Richards Heating, Cooling, & Plumbing?</h2>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>A clogged drain may seem like a minor plumbing issue. In reality, a clogged drain can leave your home at a standstill, especially for households with only one bathroom. Our team of expert plumbers offers dependable drain cleaning services, and our technicians have over 30 years of plumbing experience.</p>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>We are highly trained and licensed to work on even the most complex plumbing jobs. We can get to the source of your clogged drain immediately and provide an effective and lasting solution. The best part is, our team is available to help you 24 hours a day, 7 days a week.</p>
        </main>
    )
}

export default DrainClearing;