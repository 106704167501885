import { useEffect } from 'react';
import Helmet from "react-helmet"
import { getCurrentLocation } from "../../tools"
import siteData from "../../data";
import Showcase from '../../components/ui/Showcase';
import sewerLineReplacement from "../../assets/images/Sewer-Line-Replacement-499x281.webp"

const SewerLineReplacement = () => {
    const currentLocation = getCurrentLocation()
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <main className="mt-[100px] pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sewer Line Replacement - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Replacing your sewer line? Get peace of mind, enhanced flow, and durable solutions for your home today!" />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}sewer-line-replacement`} />
            </Helmet>
            <Showcase info={{
                title: "Need To Replace Your Sewer Line?",
                subTitle: "A.A. Richards Heating, Cooling, & Plumbing",
                description: "Replacing your sewer line? Get peace of mind, enhanced flow, and durable solutions for your home today!",
                image: {
                    src: sewerLineReplacement,
                    alt: "",
                    width: 499,
                    height: 281
                }
            }} />
            
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Call our {currentLocation.name} office at <a href={"tel:" + currentLocation.salesPhone.value} className='py-3 text-lg text-blue-500 font-bold sm:w-[60%] my-4'>{currentLocation.salesPhone.display}</a> to get started.</p>
            <h2 className='py-3 text-2xl text-center md:text-3xl font-bold'>Why Choose A.A. Richards Heating, Cooling, & Plumbing?</h2>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Plumbing problems are not always as they seem. While some issues may appear minor, they may just be an indication of a bigger problem located down to your sewer or water line. It can be a challenge to determine yourself if your sewer or water line needs repair, which is why we encourage you to reach out to our New Jersey plumbers at the first sign of a plumbing issue. For over 30 years, AA Richards Heating, Cooling & Plumbing has been a trusted service team in the area. We offer a full range of sewer and water line services from inspection to repair and replacement.</p>
        </main>
    )
}

export default SewerLineReplacement