import { useEffect } from 'react';
import Helmet from "react-helmet";
import { getCurrentLocation } from "../../tools";
import AllInOne from '../../components/AllInOne';

const Services = () => {
    
    const currentLocation = getCurrentLocation()
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <main className="mt-[100px] pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Services - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="At A.A. Richards HVAC, we provide HVAC, plumbing, and home comfort services, with certified technicians delivering tailored solutions." />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}services`} />
            </Helmet>
            <AllInOne />
        </main>
    )
}

export default Services;