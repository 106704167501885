import AboutPage from './pages/About';
import Footer from './components/Footer';
import Navbar from './components/Navbar';

import Home from './pages/Home';
import Services from './pages/services/Services';
import ContactUs from './pages/ContactUs';
import FAQ from './pages/FAQ';
import Jobs from './pages/Jobs';
import CookiesPolicy from './pages/CookiesPolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PageNotFound from './pages/PageNotFound';

// Services
import Heating from './pages/services/Heating';
import Cooling from './pages/services/Cooling';
import Plumbing from './pages/services/Plumbing';
import Furnaces from './pages/services/Furnaces';
import Filters from './pages/services/Filters';
import AirDuctCleaning from './pages/services/AirDuctCleaning';
import WaterHeaterInstallation from './pages/services/WaterHeaterInstallation';
import GasLineInstallation from './pages/services/GasLineInstallation';
import SewerLineReplacement from './pages/services/SewerLineReplacement';
import TanklessWaterHeaters from './pages/services/TanklessWaterHeaters';
import DryerVentCleaning from './pages/services/DryerVentCleaning';
import Boilers from './pages/services/Boilers';
import DrainClearing from './pages/services/DrainClearing';
import HeatPumps from './pages/services/HeatPumps';
import WaterHeaters from './pages/services/WaterHeaters';
import HeatingRepair from './pages/services/HeatingRepair';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        {/* Home Page */}
        <Route path='/' element={<Home />} />

        {/* Other Routes */}
        <Route path='/services' element={<Services />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/faq' element={<FAQ />} />
        <Route path='/jobs' element={<Jobs />} />
        <Route path='/cookies-policy' element={<CookiesPolicy />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />

        {/* Services */}
        <Route path='/heating' element={<Heating />} />
        <Route path='/cooling' element={<Cooling />} />
        <Route path='/plumbing' element={<Plumbing />} />
        <Route path='/furnaces' element={<Furnaces />} />
        <Route path='/filters' element={<Filters />} />
        <Route path='/boilers' element={<Boilers />} />
        <Route path='/water-heaters' element={<WaterHeaters />} />
        <Route path='/heating-repair' element={<HeatingRepair />} />
        <Route path='/heat-pumps' element={<HeatPumps />} />
        <Route path='/chimney-cleaning' element={<Home />} />
        <Route path='/dryer-vent-cleaning' element={<DryerVentCleaning />} />
        <Route path='/air-duct-cleaning' element={<AirDuctCleaning />} />
        <Route path='/tankless-water-heaters' element={<TanklessWaterHeaters />} />
        <Route path='/drain-clearing' element={<DrainClearing />} />
        <Route path='/gas-line-installation' element={<GasLineInstallation />} />
        <Route path='/water-heater-installation' element={<WaterHeaterInstallation />} />
        <Route path='/sewer-line-replacement' element={<SewerLineReplacement />} />

        {/* Locations */}
        <Route path='/toms-river' element={<Home />} />
        <Route path='/brick' element={<Home />} />
        <Route path='/matawan' element={<Home />} />
        <Route path='/keyport' element={<Home />} />
        <Route path='/howell' element={<Home />} />
        <Route path='/red-bank' element={<Home />} />
        <Route path='/belmar' element={<Home />} />
        <Route path='/forked-river' element={<Home />} />
        <Route path='/long-branch' element={<Home />} />
        <Route path='/colts-neck' element={<Home />} />
        <Route path='/holmdel' element={<Home />} />

        {/* Location-specific Service Routes */}
        {/* Toms River */}
        <Route path='/toms-river/heating' element={<Heating />} />
        <Route path='/toms-river/cooling' element={<Cooling />} />
        <Route path='/toms-river/plumbing' element={<Plumbing />} />
        <Route path='/toms-river/furnaces' element={<Furnaces />} />
        <Route path='/toms-river/filters' element={<Filters />} />
        <Route path='/toms-river/boilers' element={<Boilers />} />
        <Route path='/toms-river/water-heaters' element={<WaterHeaters />} />
        <Route path='/toms-river/heating-repair' element={<HeatingRepair />} />
        <Route path='/toms-river/heat-pumps' element={<HeatPumps />} />
        <Route path='/toms-river/chimney-cleaning' element={<Home />} />
        <Route path='/toms-river/dryer-vent-cleaning' element={<DryerVentCleaning />} />
        <Route path='/toms-river/air-duct-cleaning' element={<AirDuctCleaning />} />
        <Route path='/toms-river/tankless-water-heaters' element={<TanklessWaterHeaters />} />
        <Route path='/toms-river/drain-clearing' element={<DrainClearing />} />
        <Route path='/toms-river/gas-line-installation' element={<GasLineInstallation />} />
        <Route path='/toms-river/water-heater-installation' element={<WaterHeaterInstallation />} />
        <Route path='/toms-river/sewer-line-replacement' element={<SewerLineReplacement />} />

        <Route path='/toms-river/services' element={<Services />} />
        <Route path='/toms-river/about' element={<AboutPage />} />
        <Route path='/toms-river/contactus' element={<ContactUs />} />
        <Route path='/toms-river/faq' element={<FAQ />} />
        <Route path='/toms-river/jobs' element={<Jobs />} />

        {/* Brick */}
        <Route path='/brick/heating' element={<Heating />} />
        <Route path='/brick/cooling' element={<Cooling />} />
        <Route path='/brick/plumbing' element={<Plumbing />} />
        <Route path='/brick/furnaces' element={<Furnaces />} />
        <Route path='/brick/filters' element={<Filters />} />
        <Route path='/brick/boilers' element={<Boilers />} />
        <Route path='/brick/water-heaters' element={<WaterHeaters />} />
        <Route path='/brick/heating-repair' element={<HeatingRepair />} />
        <Route path='/brick/heat-pumps' element={<HeatPumps />} />
        <Route path='/brick/chimney-cleaning' element={<Home />} />
        <Route path='/brick/dryer-vent-cleaning' element={<DryerVentCleaning />} />
        <Route path='/brick/air-duct-cleaning' element={<AirDuctCleaning />} />
        <Route path='/brick/tankless-water-heaters' element={<TanklessWaterHeaters />} />
        <Route path='/brick/drain-clearing' element={<DrainClearing />} />
        <Route path='/brick/gas-line-installation' element={<GasLineInstallation />} />
        <Route path='/brick/water-heater-installation' element={<WaterHeaterInstallation />} />
        <Route path='/brick/sewer-line-replacement' element={<SewerLineReplacement />} />

        <Route path='/brick/services' element={<Services />} />
        <Route path='/brick/about' element={<AboutPage />} />
        <Route path='/brick/contactus' element={<ContactUs />} />
        <Route path='/brick/faq' element={<FAQ />} />
        <Route path='/brick/jobs' element={<Jobs />} />

        {/* Matawan */}
        <Route path='/matawan/heating' element={<Heating />} />
        <Route path='/matawan/cooling' element={<Cooling />} />
        <Route path='/matawan/plumbing' element={<Plumbing />} />
        <Route path='/matawan/furnaces' element={<Furnaces />} />
        <Route path='/matawan/filters' element={<Filters />} />
        <Route path='/matawan/boilers' element={<Boilers />} />
        <Route path='/matawan/water-heaters' element={<WaterHeaters />} />
        <Route path='/matawan/heating-repair' element={<HeatingRepair />} />
        <Route path='/matawan/heat-pumps' element={<HeatPumps />} />
        <Route path='/matawan/chimney-cleaning' element={<Home />} />
        <Route path='/matawan/dryer-vent-cleaning' element={<DryerVentCleaning />} />
        <Route path='/matawan/air-duct-cleaning' element={<AirDuctCleaning />} />
        <Route path='/matawan/tankless-water-heaters' element={<TanklessWaterHeaters />} />
        <Route path='/matawan/drain-clearing' element={<DrainClearing />} />
        <Route path='/matawan/gas-line-installation' element={<GasLineInstallation />} />
        <Route path='/matawan/water-heater-installation' element={<WaterHeaterInstallation />} />
        <Route path='/matawan/sewer-line-replacement' element={<SewerLineReplacement />} />

        <Route path='/matawan/services' element={<Services />} />
        <Route path='/matawan/about' element={<AboutPage />} />
        <Route path='/matawan/contactus' element={<ContactUs />} />
        <Route path='/matawan/faq' element={<FAQ />} />
        <Route path='/matawan/jobs' element={<Jobs />} />

        {/* Keyport */}
        <Route path='/keyport/heating' element={<Heating />} />
        <Route path='/keyport/cooling' element={<Cooling />} />
        <Route path='/keyport/plumbing' element={<Plumbing />} />
        <Route path='/keyport/furnaces' element={<Furnaces />} />
        <Route path='/keyport/filters' element={<Filters />} />
        <Route path='/keyport/boilers' element={<Boilers />} />
        <Route path='/keyport/water-heaters' element={<WaterHeaters />} />
        <Route path='/keyport/heating-repair' element={<HeatingRepair />} />
        <Route path='/keyport/heat-pumps' element={<HeatPumps />} />
        <Route path='/keyport/chimney-cleaning' element={<Home />} />
        <Route path='/keyport/dryer-vent-cleaning' element={<DryerVentCleaning />} />
        <Route path='/keyport/air-duct-cleaning' element={<AirDuctCleaning />} />
        <Route path='/keyport/tankless-water-heaters' element={<TanklessWaterHeaters />} />
        <Route path='/keyport/drain-clearing' element={<DrainClearing />} />
        <Route path='/keyport/gas-line-installation' element={<GasLineInstallation />} />
        <Route path='/keyport/water-heater-installation' element={<WaterHeaterInstallation />} />
        <Route path='/keyport/sewer-line-replacement' element={<SewerLineReplacement />} />

        <Route path='/keyport/services' element={<Services />} />
        <Route path='/keyport/about' element={<AboutPage />} />
        <Route path='/keyport/contactus' element={<ContactUs />} />
        <Route path='/keyport/faq' element={<FAQ />} />
        <Route path='/keyport/jobs' element={<Jobs />} />

        {/* Howell */}
        <Route path='/howell/heating' element={<Heating />} />
        <Route path='/howell/cooling' element={<Cooling />} />
        <Route path='/howell/plumbing' element={<Plumbing />} />
        <Route path='/howell/furnaces' element={<Furnaces />} />
        <Route path='/howell/filters' element={<Filters />} />
        <Route path='/howell/boilers' element={<Boilers />} />
        <Route path='/howell/water-heaters' element={<WaterHeaters />} />
        <Route path='/howell/heating-repair' element={<HeatingRepair />} />
        <Route path='/howell/heat-pumps' element={<HeatPumps />} />
        <Route path='/howell/chimney-cleaning' element={<Home />} />
        <Route path='/howell/dryer-vent-cleaning' element={<DryerVentCleaning />} />
        <Route path='/howell/air-duct-cleaning' element={<AirDuctCleaning />} />
        <Route path='/howell/tankless-water-heaters' element={<TanklessWaterHeaters />} />
        <Route path='/howell/drain-clearing' element={<DrainClearing />} />
        <Route path='/howell/gas-line-installation' element={<GasLineInstallation />} />
        <Route path='/howell/water-heater-installation' element={<WaterHeaterInstallation />} />
        <Route path='/howell/sewer-line-replacement' element={<SewerLineReplacement />} />

        <Route path='/howell/services' element={<Services />} />
        <Route path='/howell/about' element={<AboutPage />} />
        <Route path='/howell/contactus' element={<ContactUs />} />
        <Route path='/howell/faq' element={<FAQ />} />
        <Route path='/howell/jobs' element={<Jobs />} />

        {/* Red Bank */}
        <Route path='/red-bank/heating' element={<Heating />} />
        <Route path='/red-bank/cooling' element={<Cooling />} />
        <Route path='/red-bank/plumbing' element={<Plumbing />} />
        <Route path='/red-bank/furnaces' element={<Furnaces />} />
        <Route path='/red-bank/filters' element={<Filters />} />
        <Route path='/red-bank/boilers' element={<Boilers />} />
        <Route path='/red-bank/water-heaters' element={<WaterHeaters />} />
        <Route path='/red-bank/heating-repair' element={<HeatingRepair />} />
        <Route path='/red-bank/heat-pumps' element={<HeatPumps />} />
        <Route path='/red-bank/chimney-cleaning' element={<Home />} />
        <Route path='/red-bank/dryer-vent-cleaning' element={<DryerVentCleaning />} />
        <Route path='/red-bank/air-duct-cleaning' element={<AirDuctCleaning />} />
        <Route path='/red-bank/tankless-water-heaters' element={<TanklessWaterHeaters />} />
        <Route path='/red-bank/drain-clearing' element={<DrainClearing />} />
        <Route path='/red-bank/gas-line-installation' element={<GasLineInstallation />} />
        <Route path='/red-bank/water-heater-installation' element={<WaterHeaterInstallation />} />
        <Route path='/red-bank/sewer-line-replacement' element={<SewerLineReplacement />} />

        <Route path='/red-bank/services' element={<Services />} />
        <Route path='/red-bank/about' element={<AboutPage />} />
        <Route path='/red-bank/contactus' element={<ContactUs />} />
        <Route path='/red-bank/faq' element={<FAQ />} />
        <Route path='/red-bank/jobs' element={<Jobs />} />

        {/* Belmar */}
        <Route path='/belmar/heating' element={<Heating />} />
        <Route path='/belmar/cooling' element={<Cooling />} />
        <Route path='/belmar/plumbing' element={<Plumbing />} />
        <Route path='/belmar/furnaces' element={<Furnaces />} />
        <Route path='/belmar/filters' element={<Filters />} />
        <Route path='/belmar/boilers' element={<Boilers />} />
        <Route path='/belmar/water-heaters' element={<WaterHeaters />} />
        <Route path='/belmar/heating-repair' element={<HeatingRepair />} />
        <Route path='/belmar/heat-pumps' element={<HeatPumps />} />
        <Route path='/belmar/chimney-cleaning' element={<Home />} />
        <Route path='/belmar/dryer-vent-cleaning' element={<DryerVentCleaning />} />
        <Route path='/belmar/air-duct-cleaning' element={<AirDuctCleaning />} />
        <Route path='/belmar/tankless-water-heaters' element={<TanklessWaterHeaters />} />
        <Route path='/belmar/drain-clearing' element={<DrainClearing />} />
        <Route path='/belmar/gas-line-installation' element={<GasLineInstallation />} />
        <Route path='/belmar/water-heater-installation' element={<WaterHeaterInstallation />} />
        <Route path='/belmar/sewer-line-replacement' element={<SewerLineReplacement />} />

        <Route path='/belmar/services' element={<Services />} />
        <Route path='/belmar/about' element={<AboutPage />} />
        <Route path='/belmar/contactus' element={<ContactUs />} />
        <Route path='/belmar/faq' element={<FAQ />} />
        <Route path='/belmar/jobs' element={<Jobs />} />

        {/* Forked River */}
        <Route path='/forked-river/heating' element={<Heating />} />
        <Route path='/forked-river/cooling' element={<Cooling />} />
        <Route path='/forked-river/plumbing' element={<Plumbing />} />
        <Route path='/forked-river/furnaces' element={<Furnaces />} />
        <Route path='/forked-river/filters' element={<Filters />} />
        <Route path='/forked-river/boilers' element={<Boilers />} />
        <Route path='/forked-river/water-heaters' element={<WaterHeaters />} />
        <Route path='/forked-river/heating-repair' element={<HeatingRepair />} />
        <Route path='/forked-river/heat-pumps' element={<HeatPumps />} />
        <Route path='/forked-river/chimney-cleaning' element={<Home />} />
        <Route path='/forked-river/dryer-vent-cleaning' element={<DryerVentCleaning />} />
        <Route path='/forked-river/air-duct-cleaning' element={<AirDuctCleaning />} />
        <Route path='/forked-river/tankless-water-heaters' element={<TanklessWaterHeaters />} />
        <Route path='/forked-river/drain-clearing' element={<DrainClearing />} />
        <Route path='/forked-river/gas-line-installation' element={<GasLineInstallation />} />
        <Route path='/forked-river/water-heater-installation' element={<WaterHeaterInstallation />} />
        <Route path='/forked-river/sewer-line-replacement' element={<SewerLineReplacement />} />

        <Route path='/forked-river/services' element={<Services />} />
        <Route path='/forked-river/about' element={<AboutPage />} />
        <Route path='/forked-river/contactus' element={<ContactUs />} />
        <Route path='/forked-river/faq' element={<FAQ />} />
        <Route path='/forked-river/jobs' element={<Jobs />} />

        {/* Long Branch */}
        <Route path='/long-branch/heating' element={<Heating />} />
        <Route path='/long-branch/cooling' element={<Cooling />} />
        <Route path='/long-branch/plumbing' element={<Plumbing />} />
        <Route path='/long-branch/furnaces' element={<Furnaces />} />
        <Route path='/long-branch/filters' element={<Filters />} />
        <Route path='/long-branch/boilers' element={<Boilers />} />
        <Route path='/long-branch/water-heaters' element={<WaterHeaters />} />
        <Route path='/long-branch/heating-repair' element={<HeatingRepair />} />
        <Route path='/long-branch/heat-pumps' element={<HeatPumps />} />
        <Route path='/long-branch/chimney-cleaning' element={<Home />} />
        <Route path='/long-branch/dryer-vent-cleaning' element={<DryerVentCleaning />} />
        <Route path='/long-branch/air-duct-cleaning' element={<AirDuctCleaning />} />
        <Route path='/long-branch/tankless-water-heaters' element={<TanklessWaterHeaters />} />
        <Route path='/long-branch/drain-clearing' element={<DrainClearing />} />
        <Route path='/long-branch/gas-line-installation' element={<GasLineInstallation />} />
        <Route path='/long-branch/water-heater-installation' element={<WaterHeaterInstallation />} />
        <Route path='/long-branch/sewer-line-replacement' element={<SewerLineReplacement />} />

        <Route path='/long-branch/services' element={<Services />} />
        <Route path='/long-branch/about' element={<AboutPage />} />
        <Route path='/long-branch/contactus' element={<ContactUs />} />
        <Route path='/long-branch/faq' element={<FAQ />} />
        <Route path='/long-branch/jobs' element={<Jobs />} />

        {/* Colts Neck */}
        <Route path='/colts-neck/heating' element={<Heating />} />
        <Route path='/colts-neck/cooling' element={<Cooling />} />
        <Route path='/colts-neck/plumbing' element={<Plumbing />} />
        <Route path='/colts-neck/furnaces' element={<Furnaces />} />
        <Route path='/colts-neck/filters' element={<Filters />} />
        <Route path='/colts-neck/boilers' element={<Boilers />} />
        <Route path='/colts-neck/water-heaters' element={<WaterHeaters />} />
        <Route path='/colts-neck/heating-repair' element={<HeatingRepair />} />
        <Route path='/colts-neck/heat-pumps' element={<HeatPumps />} />
        <Route path='/colts-neck/chimney-cleaning' element={<Home />} />
        <Route path='/colts-neck/dryer-vent-cleaning' element={<DryerVentCleaning />} />
        <Route path='/colts-neck/air-duct-cleaning' element={<AirDuctCleaning />} />
        <Route path='/colts-neck/tankless-water-heaters' element={<TanklessWaterHeaters />} />
        <Route path='/colts-neck/drain-clearing' element={<DrainClearing />} />
        <Route path='/colts-neck/gas-line-installation' element={<GasLineInstallation />} />
        <Route path='/colts-neck/water-heater-installation' element={<WaterHeaterInstallation />} />
        <Route path='/colts-neck/sewer-line-replacement' element={<SewerLineReplacement />} />

        <Route path='/colts-neck/services' element={<Services />} />
        <Route path='/colts-neck/about' element={<AboutPage />} />
        <Route path='/colts-neck/contactus' element={<ContactUs />} />
        <Route path='/colts-neck/faq' element={<FAQ />} />
        <Route path='/colts-neck/jobs' element={<Jobs />} />

        {/* Holmdel */}
        <Route path='/holmdel/heating' element={<Heating />} />
        <Route path='/holmdel/cooling' element={<Cooling />} />
        <Route path='/holmdel/plumbing' element={<Plumbing />} />
        <Route path='/holmdel/furnaces' element={<Furnaces />} />
        <Route path='/holmdel/filters' element={<Filters />} />
        <Route path='/holmdel/boilers' element={<Boilers />} />
        <Route path='/holmdel/water-heaters' element={<WaterHeaters />} />
        <Route path='/holmdel/heating-repair' element={<HeatingRepair />} />
        <Route path='/holmdel/heat-pumps' element={<HeatPumps />} />
        <Route path='/holmdel/chimney-cleaning' element={<Home />} />
        <Route path='/holmdel/dryer-vent-cleaning' element={<DryerVentCleaning />} />
        <Route path='/holmdel/air-duct-cleaning' element={<AirDuctCleaning />} />
        <Route path='/holmdel/tankless-water-heaters' element={<TanklessWaterHeaters />} />
        <Route path='/holmdel/drain-clearing' element={<DrainClearing />} />
        <Route path='/holmdel/gas-line-installation' element={<GasLineInstallation />} />
        <Route path='/holmdel/water-heater-installation' element={<WaterHeaterInstallation />} />
        <Route path='/holmdel/sewer-line-replacement' element={<SewerLineReplacement />} />

        <Route path='/holmdel/services' element={<Services />} />
        <Route path='/holmdel/about' element={<AboutPage />} />
        <Route path='/holmdel/contactus' element={<ContactUs />} />
        <Route path='/holmdel/faq' element={<FAQ />} />
        <Route path='/holmdel/jobs' element={<Jobs />} />
        
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
